import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import React, { CSSProperties, FunctionComponent } from 'react';
import { useTheme } from 'styled-components';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import { Container, Text, Pill, ClearIcon, TotalPill } from './DropButton.style';

interface Props {
  ariaLabel?: string;
  ariaHasPopup?: boolean;
  ariaExpanded?: boolean;
  innerRef?: any;
  containerStyle?: CSSProperties;
  textStyle?: CSSProperties;
  tabIndex?: number;
  text: string;
  active: boolean;
  icon?: any;
  iconActive?: boolean;
  role?: string;
  onClick: () => void;
  onClear?: () => void;
  testID?: string;
  pill?: string;
  total?: number;
}

const DropButton: FunctionComponent<Props> = (props) => {
  const theme = useTheme();

  return (
    <Container
      ref={props.innerRef}
      data-testid={props.testID}
      active={props.active}
      aria-label={props.ariaLabel}
      aria-haspopup={props.ariaHasPopup}
      aria-expanded={props.ariaExpanded}
      onClick={props.onClick}
      style={props.containerStyle}
      tabIndex={props.tabIndex}
      role={props.role}
      type="button"
    >
      {typeof props.icon === 'object' && (
        <FontAwesomeIcon
          icon={props.icon}
          color={props.iconActive ? theme.primary : theme.textDark}
          style={{ fontSize: 18 }}
          testID={`${props.testID}Icon`}
        />
      )}

      {typeof props.icon === 'function' && props.icon()}

      <Text data-testid={`${props.testID}Text`} style={props.textStyle}>
        {props.text} {props.pill && <Pill>{props.pill}</Pill>}
      </Text>

      {!!props.total && <TotalPill>{props.total}</TotalPill>}

      {props.onClear && (
        <ClearIcon
          icon={faTimes}
          color={theme.textDark}
          style={{
            fontSize: 14,
            marginRight: 10,
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            props.onClear!();
          }}
          title="Clear selection"
          testID={`${props.testID || 'dropButton'}ClearIcon`}
        />
      )}

      {!props.total && (
        <FontAwesomeIcon
          icon={faChevronDown}
          color={theme.textDark}
          style={{
            fontSize: 14,
          }}
        />
      )}
    </Container>
  );
};

export default DropButton;
